/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
// import 'firebase/auth';
// import 'firebase/firestore';
// import 'firebase/functions';
import React from "react";
import { Global, css } from "@emotion/react";
// import Prism from "prism-react-renderer/prism";
import { Prism } from "prism-react-renderer"
// import { Login } from './src/components/Login';
import Layout from "./src/layout_emotion";
import "@fontsource/noto-sans-jp";

(typeof global !== "undefined" ? global : window).Prism = Prism; // gotta do this one otherwise it'll say Prism is not defined
require("prismjs/components/prism-java");
require("prismjs/components/prism-docker");
import { PageProvider } from "./src/pageContextProvider";
import "./styles.css";

export const wrapPageElement = ({ element }) => {
  return (
    <Layout>
      <Global
        styles={css`
          html,
          body {
            margin: 0px;
            font-family: "Noto Sans JP", Roboto, sans-serif;
          }
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-track {
            background-color: #e4e4e4;
            border-radius: 100px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #454a96aa;
            border-radius: 100px;
          }
        `}
      />
      <PageProvider>{element}</PageProvider>
    </Layout>
  );
};
