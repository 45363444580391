exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-algolia-search-jsx": () => import("./../../../src/pages/algoliaSearch.jsx" /* webpackChunkName: "component---src-pages-algolia-search-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contentful-blog-post-slug-jsx": () => import("./../../../src/pages/{ContentfulBlogPost.slug}.jsx" /* webpackChunkName: "component---src-pages-contentful-blog-post-slug-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mail-jsx": () => import("./../../../src/pages/mail.jsx" /* webpackChunkName: "component---src-pages-mail-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-templates-search-tags-jsx": () => import("./../../../src/templates/searchTags.jsx" /* webpackChunkName: "component---src-templates-search-tags-jsx" */)
}

