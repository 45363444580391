import tw, { css } from 'twin.macro';
import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { Link, navigate } from 'gatsby';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import HomeIcon from '@material-ui/icons/Home';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import EmailIcon from '@material-ui/icons/Email';
import SearchIcon from '@material-ui/icons/Search';

const DrawerMenu = () => {
  // メニュー開閉位置と状態をuseStateで保持
  const [state, setState] = useState({
    left: false,
  });

  // メニュー開閉
  const toggleDrawer = (anchor, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const iconList = new Array();
  iconList.push({ key: 'home', icon: HomeIcon, url: '/' });
  iconList.push({ key: 'blog', icon: MenuBookIcon, url: '/blog' });
  iconList.push({ key: 'about', icon: ContactMailIcon, url: '/about' });
  iconList.push({ key: 'mail', icon: EmailIcon, url: '/mail/' });
  // iconList.push({ key: 'arechives', icon: faBookOpen, url: '/home' });
  iconList.push({ key: 'Search', icon: SearchIcon, url: '/algoliaSearch/' });
  // iconList.push({ key: 'logout', icon: faSignOutAlt, url: '/' });

  const displayIcon = ({ key, url }) => {
    switch (key) {
      case 'home':
        return (
          <ListItem button key={key} onClick={() => navigate(url)}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={key} />
          </ListItem>
        );
      case 'blog':
        return (
          <ListItem button key={key} onClick={() => navigate(url)}>
            <ListItemIcon>
              <ContactMailIcon />
            </ListItemIcon>
            <ListItemText primary={key} />
          </ListItem>
        );
      case 'about':
        return (
          <ListItem button key={key} onClick={() => navigate(url)}>
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary={key} />
          </ListItem>
        );
      case 'mail':
        return (
          <ListItem button key={key} onClick={() => navigate(url)}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary={key} />
          </ListItem>
        );
      case 'Search':
        return (
          <ListItem button key={key} onClick={() => navigate(url)}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary={key} />
          </ListItem>
        );
      default:
        return <div>aaaa</div>;
    }
  };

  // メニューの中
  const list = anchor => (
    <div role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
      <List>
        {iconList.map(
          iconObj => displayIcon(iconObj),
          // <ListItem button key={iconObj.key}>
          //   <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
          //   <ListItemText primary={text} />
          // </ListItem>
        )}
      </List>
      <Divider />
    </div>
  );

  return (
    <div className="App">
      <div>
        <React.Fragment key="right">
          {/* menu開閉ボタン */}
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={toggleDrawer('right', true)}>
            <MenuIcon />
          </IconButton>

          {/* メニュー */}
          <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
            {list('right')}
          </Drawer>
        </React.Fragment>
      </div>
    </div>
  );
};

const MobileMenu = () => {
  return (
    <header>
      <DrawerMenu />
    </header>
  );
};

export default MobileMenu;

const mobileStyle = () => [
  css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
];
