// 実行環境のチェック（ブラウザで実行されているか）
export const isBrowser = () => typeof window !== 'undefined';

// ユーザー情報の取得
export const getUser = () =>
  isBrowser() && window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : {};

// ユーザー情報の設定
export const setUser = user => isBrowser() && window.localStorage.setItem('user', JSON.stringify(user));

// ログイン済か確認
export const isLoggedIn = () => {
  const user = getUser();
  return !!user.email;
};

// ログアウト処理
export const logout = firebase => {
  return new Promise(resolve => {
    firebase
      .auth()
      .signOut()
      .then(function() {
        setUser({});
        resolve();
      });
  });
};
