import React from "react";
import { css } from "twin.macro";
import { Link, navigate } from "gatsby";
import config from "../../../config/siteConfig";

const Footer = () => {
  return (
    <footer css={[style]}>
      <Link
        to="/privacy-policy"
        activeClassName="active"
        css={[
          css`
            flex: 1;
            text-decoration: none;
            color: inherit;
          `,
        ]}
      >
        <h4
          css={[
            css`
              margin-top: 0.1px;
              margin-bottom: 0.1px;
              text-align: center;
              color: white;
              text-align: right;
              padding: 2px;
            `,
          ]}
        >
          プライバシーポリシー
        </h4>
      </Link>
      <Link
        to="/privacy-policy"
        activeClassName="active"
        css={[
          css`
            flex: 1;
            text-decoration: none;
            color: inherit;
          `,
        ]}
      >
        <h4
          css={[
            css`
              margin-top: 0.1px;
              margin-bottom: 0.1px;
              text-align: center;
              color: white;
              text-align: left;
              padding: 2px;
            `,
          ]}
        >
          {config.copyright}
        </h4>
      </Link>
    </footer>
  );
};

export default React.memo(Footer);

const style = () => [
  css`
    background: #454a96aa;
    display: grid;
    justify-content: center;
  `,
];
