import { createContext, useState, useContext } from "react";

// Context作成
const PageContext = createContext();

// Contextを取得する関数を作成する
const usePageContext = () => useContext(PageContext);

// 引数の要素をproviderでラップするコンポーネント
const PageProvider = ({ children }) => {
  // state
  const [addisFlg, setAdodisFlg] = useState("0");

  // stateを変更
  const change = (status) => {
    setAdodisFlg(() => status);
  };

  // Contextに設定するオブジェクトに値を設定
  const contextValue = {
    addisFlg,
    change,
    setAdodisFlg,
  };

  return (
    // 作成したContextのproviderでラップする
    <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>
  );
};

// 関数とコンポーネントをexportしておく
export { usePageContext, PageProvider };
