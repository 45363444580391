import tw, { css } from "twin.macro";
import { BsExclamationTriangle } from "react-icons/bs";
import { IconContext } from "react-icons";
import { useLocation } from "@reach/router";

const Stema = () => {
  const { pathname } = useLocation();

  //パスを取得
  const pathArray = pathname.split("/");
  //   パスを配列化
  const pathFirst = pathArray[1];

  return (
    <div css={[disp(pathname, pathFirst)]}>
      <IconContext.Provider value={{ size: "25px" }}>
        <div css={[style]}>
          <BsExclamationTriangle />
          <p
            css={[
              css`
                font-size: 15px;
                margin: 0px;
              `,
            ]}
          >
            当サイトは、アフィリエイト広告を利用しています
          </p>
        </div>
      </IconContext.Provider>
    </div>
  );
};

export default Stema;

// アフィリエイト広告がないページは出ないようにする
const disp = (path, pathFirst) => [
  css`
    display: flex;
    padding-top: 25px;
    justify-content: center;
    display: ${path === "/" ||
    path === "/about/" ||
    path === "/blog/" ||
    path === "/mail/" ||
    path === "/404/" ||
    pathFirst === "contentfulTags" ||
    path === "/algoliaSearch/"
      ? "none"
      : ""};
  `,
];

// タグリストのスタイル
const style = (theme) => {
  return [
    css`
      background-color: ${theme.tagColor};
      display: flex;
      gap: 10px;
    `,
    tw`
    //   inline-block
      rounded-full
      // bg-blue-400
      // hover:bg-blue-500
      duration-300 
      // text-xs font-bold 
      mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
      // opacity-90 hover:opacity-100
  `,
  ];
};
