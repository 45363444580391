import React, { useEffect, useState } from "react";
import tw, { css } from "twin.macro";
import { useLocation } from "@reach/router";
import Header from "../header";
import Footer from "../footer";
import Stema from "../stema";

const Navigation = (props) => {
  const [path, setPath] = useState("/");

  // useEffect(() => {
  //   setPath(() => props.children.key);
  // }, [props.children.key]);
  const { pathname } = useLocation();
  useEffect(() => {
    setPath(() => pathname);
  }, [pathname]);

  return (
    <div css={[contextStyle.contexContainer]}>
      {path !== "/" && (
        <div css={[header]}>
          <Header />
        </div>
      )}
      <Stema />
      <div css={[main]}>{props.children}</div>
      {/* <ScrollToTop /> */}
      <div css={[footer]}>
        <Footer />
      </div>
    </div>
  );
};

export default Navigation;
const header = () => [
  css`
    height: 10%;
    position: sticky;
    top: 0;
    z-index: 30;
  `,
];

const main = () => [
  css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    flex: 1;
    height: 80%;
    // width: 100vw;
  `,
];

const footer = () => [
  css`
    // background: #454a96aa;
    height: 10%;
    text-align: center;
  `,
];

// 全体のスタイル
const contextStyle = {
  contexContainer: (theme) => [
    css`
      margin: 0;
      display: flex;
      flex-flow: column;
      // height: 100vh;
      background-color: ${theme.background};
      padding: 0;
      flex: 1;
    `,
  ],
};

// タグリストのスタイル
const tags = (theme) => {
  return [
    css`
      // color: ${theme.color};
      // cursor: pointer;
      background-color: ${theme.tagColor};
    `,

    tw`
      inline-block
      rounded-full
      // bg-blue-400
      // hover:bg-blue-500
      duration-300 
      // text-xs font-bold 
      mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
      // opacity-90 hover:opacity-100
  `,
  ];
};

// // コンテンツのスタイル
// const contentsStyle = {
//   // コンテンツ全体のスタイル
//   container: () => [
//     css`
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       height: 100%;
//       background-color: orange;
//       padding: 10px;
//     `,
//   ],

//   // コンテンツのスタイル
//   contentsContainer: () => [
//     css`
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       width: 50vw;
//       background: pink;
//       gap: 10px;
//       padding: 10px;
//     `,
//   ],

//   // 1行のスタイル
//   rowContainer: () => [
//     css`
//       display: flex;
//       justify-content: flex-center;
//       align-items: center;
//       background-color: green;
//     `,
//   ],

//   // 要素のスタイル
//   item: () => [
//     css`
//       display: flex;
//       justify-content: flex-start;
//       flex: 1;
//       background-color: yellow;
//       margin: 5px;
//     `,
//   ],
// };
