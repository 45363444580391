import React, { useEffect, useState } from "react";
import { ThemeProvider as EmotionProvider } from "@emotion/react";
import { ThemeContext } from "./themeContext";
import getTheme from "./theme_emotion";

const ThemeProvider = ({ children }) => {
  // useStateでモードを管理する（stateの値をcontextProvidernに渡す）
  const [colorMode, setColorMode] = useState("dark");

  // // ブラウザのモードで再判定する
  // useEffect(() => {
  //   setColorMode(() => {
  //     if (sessionStorage.getItem('first')) {
  //       return sessionStorage.getItem('first');
  //     }
  //     if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  //       return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  //     }
  //   });

  //   sessionStorage.setItem('first', colorMode);
  // }, [colorMode]);

  // 選択タグのstate
  const [selectedTag, selectTag] = useState([]);

  // モードを切り替える関数
  const toggleColorMode = () => {
    // colorMode切り替え用関数
    setColorMode(colorMode === "light" ? "dark" : "light");
  };
  return (
    // emotion-themingのthemeProvider
    <EmotionProvider theme={getTheme(colorMode)}>
      {/* useContextのprivderにモードを管理してるstateを渡し
        内部のcomponetでsetColorModeでstateを変更した場合、
        EmotionProviderに渡しているcolorModeが切り替わる
     */}
      <ThemeContext.Provider
        value={{
          colorMode,
          setColorMode: toggleColorMode,
          selectedTag,
          selectTag,
        }}
      >
        {children}
      </ThemeContext.Provider>
    </EmotionProvider>
  );
};

export default ThemeProvider;
