/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import { useLocation } from '@reach/router';
import Navigation from '../components/navigation';
import ThemeProvider from './theme_emotionProvider';
import '@fontsource/noto-sans-jp';

const Layout = props => {
  const [path, setPath] = useState('/');

  const { pathname } = useLocation();
  useEffect(() => {
    setPath(() => pathname);

    // return () => setPath(null);
  }, [pathname]);

  return (
    <ThemeProvider>
      <div css={[contentsContainer(path)]}>
        <Navigation {...props} />
      </div>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

// コンテンツのスタイル
const contentsContainer = pathname => [
  css`
    display: flex;
    flex-direction: column;
    min-height: ${pathname === '/' && '100vh'};
    min-height: ${pathname === '/algoliaSearch/' && '100vh'};
    min-height: ${pathname === '/mail/' && '100vh'};
    min-height: ${pathname.substr(1, 14) === 'contentfulTags' && '100vh'};
  `,
];
