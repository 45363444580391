// テーマを定義する
const lightTheme = {
  background: '#ffffff',
  color: '#000000',
  tagColor: 'rgb(0, 143, 131)',
  indexHighLight: '#06bbbc',
  hoverColor: '#CCFFFF',
};

const darkTheme = {
  background: '#222639',
  color: '#f0f5fa',
  tagColor: '#efbb35',
  indexHighLight: 'purple',
  hoverColor: '#000080',
};

// 使用するテーマを返す関数
// 引数としてはuseStateの値を受け取る
export default function getTheme(colorMode) {
  // mode受けてテーマ返す
  switch (colorMode) {
    case 'light':
      return lightTheme;
    case 'dark':
      return darkTheme;
    default:
      return lightTheme;
  }
}
