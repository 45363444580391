// React useContextで現在のモードを管理する
import { createContext, useContext } from 'react';

const defaultContext = {
  colorMode: 'dark', // 現在のモードを管理
  setColorMode: () => {}, // colorMode書き換え用の関数を渡す
};

// コンテキスト作成
export const ThemeContext = createContext(defaultContext);
// useThemeでコンテキストを取得できるようにする
export const useTheme = () => useContext(ThemeContext);
