module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-MLXMSQRMN0"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://scrawledtechblog.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/techIcon.jpeg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"846e3a5b971b1ff2fda4d3c80952781d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#f39c12","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-autolink-headers","id":"bb75eb72-7c31-598e-bec1-1da33c4386f2","name":"gatsby-remark-autolink-headers","version":"6.13.1","modulePath":"/opt/build/repo/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"icon":false,"offsetY":0,"className":"anchor"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-external-links","id":"47bea4d0-a863-51f1-8d8b-789cb21b0565","name":"gatsby-remark-external-links","version":"0.0.4","modulePath":"/opt/build/repo/node_modules/gatsby-remark-external-links/index.js","pluginOptions":{"plugins":[],"target":"_blank","rel":"nofollow noreferrer"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images-contentful","id":"3636032b-70ba-5ccc-a956-cc6c7018f92d","name":"gatsby-remark-images-contentful","version":"6.13.1","modulePath":"/opt/build/repo/node_modules/gatsby-remark-images-contentful/index.js","pluginOptions":{"plugins":[],"maxWidth":900,"withWebp":true},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-plugin-catch-links","id":"4e3ac211-6635-53ed-9432-90cbe6ac9e68","name":"gatsby-plugin-catch-links","version":"5.13.1","modulePath":"/opt/build/repo/node_modules/gatsby-plugin-catch-links/index.js","module":{},"pluginOptions":{"plugins":[]},"nodeAPIs":["onPreInit"],"browserAPIs":["onClientEntry"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
